import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import cn from 'classnames';
import { useAction } from 'hooks';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../../components/DownloadDataModal/DownloadDataModal.pcss';


interface ActionsProps {
  onSetComponent: (componentName: string) => void,
}

interface Props {
  onSetComponent: (componentName: string) => void,
  onSetHeaderMessage: (headerMessage?: MessageDescriptor) => void,
}


const useIsDownloaderAppCloseNeeded = () => {
  const status = useSelector(selectors.status);
  return status === constants.DOWNLOADER_STATUSES.UPDATE_ENDPOINT_NOT_FOUND;
};


const Actions: FC<ActionsProps> = ({ onSetComponent }) => {
  const isDownloaderAppCloseNeeded = useIsDownloaderAppCloseNeeded();
  const updateDownloader = useAction(actions.updateDownloader);

  const onUpdate = () => {
    if (isDownloaderAppCloseNeeded) {
      onSetComponent('Initializing');
      return;
    }
    updateDownloader();
  };

  useEffect(() => {
    onUpdate();
  }, []);

  return null;
};


const UpdateError: FC<Props> = ({ onSetComponent, onSetHeaderMessage }) => {
  const logEvent = useAction(actions.logEvent, 'UpgradePromptDisplayed');

  useEffect(() => {
    onSetHeaderMessage(messages.downloaderOutdated.updateErrorHeader);
    logEvent();
    return () => onSetHeaderMessage();
  }, []);

  return (
    <div>
      <div className={styles.updateError}>
        <div className={cn(styles.updateError__info, 'mb-6')}>
          <FormattedMessage {...messages.downloaderOutdated.updateErrorInfo} />
        </div>
        <div className={styles.updateError__info}>
          <FormattedMessage {...messages.downloaderOutdated.updateErrorInfo2} />
        </div>
        <ol className={styles.updateError__steps}>
          {
            Object.keys(messages.downloaderOutdated.updateErrorSteps).map((key) => (
              <li>
                <FormattedMessage {...messages.downloaderOutdated.updateErrorSteps[key]} />
              </li>
            ))
          }
        </ol>
      </div>
      <Actions {...{ onSetComponent }} />
    </div>
  );
};

export default UpdateError;
