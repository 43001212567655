import React, { FC, memo, ReactElement } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './StackDataList.pcss';


interface StackDataListProps {
  data: Record<string, string | number | ReactElement>[];
}


const StackDataList: FC<StackDataListProps> = ({ data }) => {
  useStyles(styles);

  return (
    <div className={styles.dataList}>
      {
        data.map((dataElem) => (
          <div className={styles.dataList__elem} key={`${dataElem.label}_${dataElem.value}`}>
            <div className={styles.dataList__label}>{ dataElem.label }</div>
            <div className={styles.dataList__value}>{ dataElem.value }</div>
          </div>
        ))
      }
    </div>
  );
};

export default memo(StackDataList);
