import React, { FC, memo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import messages from '../../../messages';
import StackDataList from '../StackDataList';
import styles from './RelatedData.pcss';


interface Props {
  element: Record<string, string | number>;
}


const PillTooltip: FC<Props> = ({ element }) => {
  useStyles(styles);

  const unitLabel = () => {
    switch (element.unit) {
      case 1:
        return <FormattedMessage {...messages.pill.labels.units.injections} />;
      case 2:
        return <FormattedMessage {...messages.pill.labels.units.pills} />;
      case 3:
        return <FormattedMessage {...messages.pill.labels.units.milligrams} />;
      case 4:
        return <FormattedMessage {...messages.pill.labels.units.units} />;
      default:
        return <FormattedMessage {...messages.pill.labels.units.none} />;
    }
  };

  const data = [
    {
      label: element.name,
      value: <>{ element.value } { unitLabel() }</>,
    },
    {
      label: <FormattedMessage {...messages.activity.labels.time} />,
      value: moment.unix(element.timestamp as number).utc().format('LT'),
    },
  ];


  return (
    <>
      <div className={styles.tooltipTitle}>
        <div className={styles.tooltipTitle__titleText}>
          <FormattedMessage {...messages.pill.label} />
        </div>
      </div>
      <StackDataList data={data} />
    </>
  );
};

export default memo(PillTooltip);
