import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import find from 'lodash/find';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import round from 'lodash/round';
import join from 'lodash/join';
import partition from 'lodash/partition';
import moment from 'moment';


export const calculateActivities = (activities) => activities.reduce((a, b) => {
  const { value } = b;
  return (a + (value || 0));
}, 0);


export const calculateCarbs = (foods) => (foods
  ? foods.reduce(
    (a, b) => a + (b.nutritions
      ? b.nutritions.reduce(
        (result, nextValue) => result
  + (nextValue.unit === 2 ? (nextValue.value / 1000).toFixed(2) : parseFloat(nextValue.value.toFixed(2), 10) || 0),
        0,
      )
      : 0 || 0), 0,
  )
  : 0);


export const calculateMedicationFastValue = (medications) => medications.filter(
  (item) => item.name === 'Fast-acting Insulin',
).reduce((a, b) => a + (b.value || 0), 0);


export const calculateMedicationLongValue = (medications) => medications.filter(
  (item) => item.name === 'Long-acting Insulin',
).reduce((a, b) => a + (b.value || 0), 0);


export const calculatePills = (medications) => medications.filter(
  (item) => item.name !== 'Fast-acting Insulin' && item.name !== 'Long-acting Insulin',
);


export const calculateRelatedValue = (relatedData) => {
  if (relatedData.activities && relatedData.foods && relatedData.medications) {

    const activityValue = calculateActivities(relatedData.activities);
    const foodValue = calculateCarbs(relatedData.foods);
    const medicationFastValue = calculateMedicationFastValue(relatedData.medications);
    const medicationLongValue = calculateMedicationLongValue(relatedData.medications);
    const pillsItems = calculatePills(relatedData.medications);

    return {
      activityValue,
      foodValue,
      medicationFastValue,
      medicationLongValue,
      pillsCount: pillsItems.length,
      pillsItems,
    };
  }
  return {
    activityValue      : 0,
    foodValue          : 0,
    medicationFastValue: 0,
    medicationLongValue: 0,
    pillsCount         : 0,
    pillsItems         : [],
  };
};


export const getUnitMedication = (medication) => {
  switch (medication.unit) {
    case 1:
      return 'injections';
    case 2:
      return 'pills';
    case 3:
      return 'milligrams';
    case 4:
      return 'units';

    default:
      return 'none';
  }
};


export function getEndTimeTooltip(object, timestamp, index, pointMargins) {
  const arrayTimestamps = Object.keys(object);
  let { endTimestamp: endTooltip } = maxBy(object[timestamp], 'endTimestamp');
  if (arrayTimestamps.length - 1 > index) {
    const startNextObject = minBy(object[arrayTimestamps[index + 1]], 'deviceDateTime').deviceDateTime;
    if (startNextObject && startNextObject < endTooltip) {
      endTooltip = startNextObject;
    }
  }

  return Math.max(endTooltip - pointMargins, timestamp);
}


export function prepareMeasurements(measurements, type, metricConversions, firstTick, lastTick, pointMargins) {
  let array = measurements.map((measurement) => ({
    x: measurement.timestamp,
    y: metricConversions[type].toDisplay(measurement.value),
    measurement,
  }));

  if (!array.find((measurement) => measurement.timestamp === firstTick)) {
    array.push({
      x: firstTick,
      y: 0,
    });
  }

  if (!array.find((measurement) => measurement.timestamp === lastTick)) {
    array.push({
      x: lastTick,
      y: 0,
    });
  }

  array = array.sort((a, b) => a.x - b.x);

  array = array.map((data, index) => {
    if (array.length - 1 > index) {
      data.xEnd = array[index + 1].x;
      data.yEnd = array[index + 1].y;
    }

    if (index === 0) {
      return data;
    }

    data.prevX = array[index - 1].x;
    data.prevY = array[index - 1].y;

    if (data.y <= 0) {
      data.y = array[index - 1].y;
    }

    return data;
  }).filter((measurement) => measurement.x >= firstTick && measurement.x < lastTick);

  array.forEach((element, index) => {
    if (array.length - 1 > index) {
      array.push({
        ...element,
        x     : Math.max(element.xEnd - pointMargins, element.x),
        y     : element.yEnd || element.y,
        yStart: element.y,
        xStart: element.x,
      });
    }
  });

  array = array.sort(
    (a, b) => a.x - b.x,
  );

  return array;
}


export function calculateRelatedData(relatedData, lastTick) {
  const daySeconds = 86400;
  const hourSeconds = 3600;
  const relatedDataGrouped = {
    medications: [],
    activities : [],
  };

  relatedData.forEach((data) => {
    const { activities, medications, deviceDateTime } = data;

    relatedDataGrouped.activities = relatedDataGrouped.activities.concat(activities.map((activity) => {
      activity.deviceDateTime = deviceDateTime;
      let timeAfterRange = 0;
      if (activity.deviceDateTime + hourSeconds > lastTick) {
        timeAfterRange = activity.deviceDateTime + hourSeconds - lastTick;
      }
      activity.endTimestamp = deviceDateTime + hourSeconds - timeAfterRange;
      return activity;
    }));

    relatedDataGrouped.medications = relatedDataGrouped.medications.concat(medications.map((medication) => {
      if (medication.name !== 'Long-acting Insulin' && medication.name !== 'Fast-acting Insulin') {
        return null;
      }
      medication.deviceDateTime = deviceDateTime;
      const extraTime = medication.name === 'Fast-acting Insulin' ? hourSeconds * 6 : daySeconds;
      let timeAfterRange = 0;
      if (medication.deviceDateTime + extraTime > lastTick) {
        timeAfterRange = medication.deviceDateTime + extraTime - lastTick;
      }
      medication.endTimestamp = deviceDateTime + extraTime - timeAfterRange;
      medication.endTooltip = medication.endTimestamp;
      return medication;
    })).filter((medication) => medication);

  });

  return relatedDataGrouped;
}


export function translateMeal(meal) {
  const { nutritions } = meal;
  return {
    ...meal,
    carbs       : get(find(nutritions, { type: 'Carbohydrates' }), 'value'),
    fat         : get(find(nutritions, { type: 'FatTotal' }), 'value'),
    protein     : get(find(nutritions, { type: 'Protein' }), 'value'),
    energy      : get(find(nutritions, { type: 'Energy' }), 'value'),
    type        : 'food',
    isSnaqImport: true,
  };
}


export function translateTimeSeriesResources(timeSeriesResources) {
  const result = [];

  forEach(timeSeriesResources, (timeSeriesResource) => {
    const { resources } = timeSeriesResource;
    const meals = filter(resources, { resourceType: 'Meal' });
    if (!meals || !meals.length) {
      return;
    }
    result.push(...map(meals, translateMeal));
  });

  return result;
}


export function translateRelatedDataIntoMeals(relatedData) {
  const result = [];

  forEach(relatedData, (data) => {
    const { foods, deviceDateTime, dateTime } = data;
    if (!foods || !foods.length) {
      return;
    }
    const filteredFoods = [];
    forEach(foods, (food) => {
      if (food.isDeleted) return;
      const carbs = get(find(food.nutritions, { type: 'Carbs' }), 'value', 0);
      filteredFoods.push({ ...food, carbs });
    });
    if (!filteredFoods.length) {
      return;
    }
    // TODO: Remove aggregation if stacking is done
    const name = join(map(filteredFoods, 'name'), ', ');
    const carbs = round(reduce(
      filteredFoods,
      (acc, food) => acc + food.carbs,
      0,
    ));
    const mappedMeal = {
      id          : filteredFoods[0],
      name,
      carbs,
      timestamp   : deviceDateTime || dateTime,
      type        : 'food',
      isSnaqImport: false,
    };
    result.push(mappedMeal);
  });

  return result;
}


export function mergeDataIntoMeals(relatedData, timeSeriesResources) {
  return [
    ...translateRelatedDataIntoMeals(relatedData),
    ...translateTimeSeriesResources(timeSeriesResources),
  ];
}


export function translateMealsIntoChartData(meals, firstTick) {
  // empty ranges are needed to separate fulfilled chart area based on timestamp & meal component width & scale
  const emptyRanges = [{
    x: firstTick,
    y: 1,
  }];
  const sortedMeals = sortBy(meals, 'timestamp');
  forEach(sortedMeals, (meal) => {
    const { timestamp } = meal;
    emptyRanges.push({
      // to be improved, random value - need to use xScale
      x: timestamp - 100000,
      y: 1,
    });
  });
  return [
    {
      id  : 'meals',
      data: map(meals, (meal) => ({
        x: meal.timestamp,
        y: 1,
        meal,
      })),
    },
    {
      id  : 'meals-empty',
      data: emptyRanges,
    },
  ];
}

export function translateReadingRelatedData(relatedData) {
  const translatedData = [];

  if (!relatedData) {
    return translatedData;
  }

  const { deviceDateTime, dateTime, foods } = relatedData;
  const timestamp = deviceDateTime || dateTime;
  const filteredFoods = [];
  let carbs = 0;

  if (foods && foods.length) {
    forEach(foods, (food) => {
      const foundFood = find(food.nutritions, { type: 'Carbs' });
      if (food.isDeleted) return;
      carbs += get(foundFood, 'value', 0);

      filteredFoods.push({ ...food, carbs });
    });

    if (filteredFoods.length) {
      const name = join(map(filteredFoods, 'name'), ', ');
      translatedData.push({
        timestamp,
        name,
        carbs,
        type: 'food',
      });
    }
  }

  forEach(relatedData.activities, (activity) => {
    const { endTimestamp } = activity;
    const duration = moment.duration(endTimestamp - deviceDateTime, 'seconds').asMinutes();
    translatedData.push({
      ...activity,
      timestamp,
      duration,
      type: 'activity',
    });
  });

  const [injections, pills] = partition(relatedData.medications, (medication) => (
    medication.name === 'Fast-acting Insulin' || medication.name === 'Long-acting Insulin'
  ));
  forEach(injections, (injection) => {
    translatedData.push({
      ...injection,
      type: 'injection',
      timestamp,
    });
  });
  forEach(pills, (pill) => {
    translatedData.push({
      ...pill,
      type: 'pill',
      timestamp,
    });
  });

  return translatedData;
}


export function getUniqRelatedData(relatedData, timeSeriesResources) {
  return filter(
    relatedData,
    (rb) => {
      const { timestamp, type, carbs } = rb;
      const tsr = find(timeSeriesResources, { timestamp, type, carbs });
      if (tsr) return false;
      return true;
    },
  );
}

