import cn from 'classnames';
import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './Dots.pcss';


interface DotsProps {
  currentIndex: number;
  indexes: number[]
}

const Dots: FC<DotsProps> = ({ currentIndex, indexes }) => {
  useStyles(styles);

  return (

    <div className={styles.dots}>
      {
        indexes.map((index) => (
          <div
            key={index}
            className={cn(styles.dots__dot, { [styles['dots__dot--active']]: currentIndex === index })}
          />
        ))
      }
    </div>
  );
};

export default Dots;
