import React, { FC, memo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import { formatTimestamp } from 'helpers/datetime';
import messages from '../../../messages';
import StackDataList from '../StackDataList';
import styles from './RelatedData.pcss';


interface Props {
  element: Record<string, string | number>;
}


const MealTooltip: FC<Props> = ({ element }) => {
  useStyles(styles);
  const nutrients = ['carbs', 'protein', 'energy', 'fat'];

  const getUnit = (type: string) => {
    switch (type) {
      case 'energy':
        return <FormattedMessage {...messages.meal.labels.energyUnit} />;
      default:
        return <FormattedMessage {...messages.meal.labels.nutrientUnit} />;
    }
  };

  const nutrientsLabel = {
    carbs  : <FormattedMessage {...messages.meal.labels.carbs} />,
    protein: <FormattedMessage {...messages.meal.labels.protein} />,
    energy : <FormattedMessage {...messages.meal.labels.energy} />,
    fat    : <FormattedMessage {...messages.meal.labels.fat} />,
  };

  const nutrientsData = nutrients.reduce((result, nutrient) => {
    if (!element[nutrient]) {
      return result;
    }

    return [
      ...result,
      {
        label: <>{ nutrientsLabel[nutrient] } ({ getUnit(nutrient) })</>,
        value: element[nutrient],
      },
    ];
  }, []);

  const data = [
    {
      label: formatTimestamp(element.timestamp, 'DD/MM/YYYY'),
      value: <span>{ formatTimestamp(element.timestamp, 'HH:mm') }</span>,
    },
    {
      label: <FormattedMessage {...messages.activity.labels.time} />,
      value: moment.unix(element.timestamp as number).utc().format('LT'),
    },
    ...nutrientsData,
  ];


  return (
    <>
      <div className={styles.tooltipTitle}>
        {
          element.isSnaqImport
          && <img src="/assets/svg/snaq.svg" alt="snaq.io" className={styles.tooltipTitle__snaqIcon} />
        }
        <div className={styles.tooltipTitle__titleText}>
          { element.name }
        </div>
      </div>
      <StackDataList data={data} />
    </>
  );
};

export default memo(MealTooltip);
