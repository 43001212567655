import { call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import queryString from 'query-string';
import AlertService from 'services/AlertService';
import NotificationsService from 'services/NotificationsService';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import * as actionTypes from './actionTypes';
import * as actions from './actions';


function* fetchList() {
  const alert = yield call(AlertService.createAlertDispatcher, { isGlobal: true });
  try {
    const requestUrl = '/api/Notification';
    const notifications = yield call(ApiService.regionalRequest, requestUrl);
    yield put(actions.fetchListSuccess(notifications));
  } catch (err) {
    yield put(actions.fetchListError(err));
    yield call(App.dispatchError, err, null, alert);
  }
}


function* dismissNotification({ payload }) {
  const { notification } = payload;
  try {
    const { instantNotificationId } = notification;
    //  console.info('dismissNotification', notification);
    yield delay(300); // Wait for animation
    const requestUrl = '/api/Notification/dismissed';
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : { instantNotificationId },
    });
    yield put(actions.dismissNotificationSuccess(notification));
  } catch (err) {
    yield put(actions.dismissNotificationError(err, notification));
    yield call(App.dispatchError, err);
  }
}


function* markNotificationsRead({ payload }) {
  const { notificationsIds } = payload;
  try {
    if (!notificationsIds.length) {
      yield put(actions.markNotificationsReadSuccess());
      return;
    }
    const requestUrl = '/api/Notification/read';
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : { instantNotificationIds: notificationsIds },
    });
    yield put(actions.markNotificationsReadSuccess());
  } catch (err) {
    yield put(actions.markNotificationsReadError(err, notificationsIds));
    yield call(App.dispatchError, err);
  }
}


function* sendClinicPatientNewReadingsAddedNotification({ payload }) {
  try {
    yield call(NotificationsService.sendClinicPatientNewReadingsAddedNotification, payload);
    yield put(actions.sendNotificationSuccess());
  } catch (err) {
    yield put(actions.sendNotificationError(err));
  }
}


function* fetchNotificationsHistory({ payload }) {
  try {
    const query = queryString.stringify(payload);
    const requestUrl = `/api/Notification/filter?${query}`;
    const response = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'GET',
    });
    yield put(actions.fetchNotificationsHistorySuccess(response.result));
  } catch (err) {
    yield put(actions.fetchNotificationsHistoryError(err));
    yield call(App.dispatchError, err);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.FETCH_LIST, fetchList);
  yield takeEvery(actionTypes.DISMISS_NOTIFICATION, dismissNotification);
  yield takeLatest(actionTypes.MARK_NOTIFICATIONS_READ, markNotificationsRead);
  // eslint-disable-next-line max-len
  yield takeEvery(actionTypes.SEND_CLINIC_PATIENT_NEW_READINGS_ADDED_NOTIFICATION, sendClinicPatientNewReadingsAddedNotification);
  yield takeLatest(actionTypes.FETCH_NOTIFICATIONS_HISTORY, fetchNotificationsHistory);
}

export default [
  sagas,
];
