import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import App from 'modules/App';
import FormGroup from 'components/Form/FormGroup';
import Select from 'components/Form/Select';
import Button from 'components/Form/Button';
import Widget from 'components/Widget';
import intlShape from 'shapes/intlShape';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../Results/Results.pcss';


class CgmTrendChartWidget extends React.PureComponent {

  static propTypes = {
    // Explicit props
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }).isRequired,
    renderDatePresets      : PropTypes.func,
    className              : PropTypes.string,
    start                  : PropTypes.number,
    end                    : PropTypes.number,
    // Explicit actions
    onDatesChange          : PropTypes.func,
    // Implicit props
    mode                   : PropTypes.oneOf(constants.MODES),
    deviceMode             : PropTypes.oneOf(constants.DEVICES_MODES),
    calculationFormula     : PropTypes.oneOf(constants.CALCULATION_FORMULAS_CGM),
    aggregateBy            : PropTypes.oneOf(constants.AGGREGATE_BY),
    groupBy                : PropTypes.oneOf(constants.GROUP_BY_CGM),
    intl                   : intlShape,
    printMode              : PropTypes.bool,
    // Implicit actions
    onSetMode              : PropTypes.func,
    onSetDeviceMode        : PropTypes.func,
    onSetCalculationFormula: PropTypes.func,
    onSetAggregateBy       : PropTypes.func,
    onSetGroupBy           : PropTypes.func,
  };


  renderPresentationTypes() {
    return (
      <div>
        <p className="form-label"><FormattedMessage {...messages.labels.presentationTypes} /></p>
        <div className="d-flex">
          <div className="btn-group">
            {
              map(constants.MODES, (mode) => (
                <Button
                  key={mode}
                  styleModifier="quaternary"
                  className={cn('btn--filled', { 'text--primary': mode === this.props.mode })}
                  isDisabled={mode === 'AGGREGATED'}
                  labelMessage={messages.buttons[mode.toLowerCase()]}
                  onClick={() => this.props.onSetMode(mode)}
                />
              ))
            }
          </div>
          <div className="btn-group ml-4">
            {
              map(constants.DEVICES_MODES, (mode) => (
                <Button
                  key={mode}
                  styleModifier="quaternary"
                  className={cn('btn--filled', { 'text--primary': mode === this.props.deviceMode })}
                  labelMessage={messages.buttons[mode.toLowerCase()]}
                  onClick={() => this.props.onSetDeviceMode(mode)}
                />
              ))
            }
          </div>
        </div>
      </div>
    );
  }


  renderCalculationFormulas() {
    const { mode, calculationFormula, intl } = this.props;
    const calculationFormulas = [...constants.CALCULATION_FORMULAS_CGM];
    if (mode === 'AGGREGATED') {
      calculationFormulas.shift();
    }
    return (
      <FormGroup
        id="calculationFormula"
        labelMessage={messages.labels.calculationFormula}
        className={styles.sortOptions__option}
        formValues={{ values: { calculationFormula } }}
      >
        <Select
          optionsFrom={map(calculationFormulas, (formula) => ({
            value: formula,
            label: intl.formatMessage(messages.formulas[camelCase(formula)]),
          }))}
          valueKey="value"
          labelKey="label"
          onChange={(input) => this.props.onSetCalculationFormula(input.value)}
        />
      </FormGroup>
    );
  }


  renderAggregateBy() {
    const { mode, aggregateBy, intl } = this.props;
    if (mode !== 'AGGREGATED') {
      return null;
    }

    return (
      <FormGroup
        id="aggregateBy"
        labelMessage={messages.labels.aggregateBy}
        className={styles.sortOptions__option}
        formValues={{ values: { aggregateBy } }}
      >
        <Select
          optionsFrom={map(constants.AGGREGATE_BY, (unit) => ({
            value: unit,
            label: intl.formatMessage(messages.aggregateBy[unit.toLowerCase()]),
          }))}
          valueKey="value"
          labelKey="label"
          onChange={(input) => this.props.onSetAggregateBy(input.value)}
        />
      </FormGroup>
    );
  }


  renderGroupBy() {
    const { mode, groupBy, intl } = this.props;
    if (mode !== 'GROUPED') {
      return null;
    }
    return (
      <FormGroup
        id="groupBy"
        labelMessage={messages.labels.groupBy}
        className={styles.sortOptions__option}
        formValues={{ values: { groupBy } }}
      >
        <Select
          optionsFrom={map(constants.GROUP_BY_CGM, (type) => ({
            value: type,
            label: intl.formatMessage(messages.groupBy[type.toLowerCase()]),
          }))}
          valueKey="value"
          labelKey="label"
          onChange={(input) => this.props.onSetGroupBy(input.value)}
        />
      </FormGroup>
    );
  }


  renderPresentationOptions() {
    if (this.props.mode === 'ALL') {
      return null;
    }

    return (
      <div className="d-flex justify-content-end">
        { this.renderCalculationFormulas() }
        { this.renderAggregateBy() }
        { this.renderGroupBy() }
      </div>
    );
  }


  renderDatePresets() {
    return (
      <div>
        <p className="form-label"><FormattedMessage {...messages.labels.periodWithResults} /></p>
        { this.props.renderDatePresets() }
      </div>
    );
  }


  renderChartControls() {
    return (
      <div className={styles.chartControls}>
        <div className="row">
          <div className="col-6">
            { this.renderPresentationTypes() }
          </div>
          <div className="col-6">
            { this.renderPresentationOptions() }
          </div>
        </div>
        <div className="mt-4 mb-4">
          { this.renderDatePresets() }
        </div>
      </div>
    );
  }


  render() {
    const {
      className,
      printMode,
    } = this.props;

    return (
      <Widget        
        widgetId={constants.CGM_TREND_CHART_WIDGET}
        headerMessage={messages.headers.bloodGlucoseConcentration}
        className={cn(className, 'mb-1')}
      >
        { !printMode && this.renderChartControls() }
        <div className={styles.wrapper}>
          <AmbulatoryGlucoseProfile.components.ProfileChart
            conversion={this.props.conversion}
            standards={this.props.standards}
            mode={this.props.mode}
            start={this.props.start}
            end={this.props.end}
            onDatesChange={this.props.onDatesChange}
          />
        </div>
      </Widget>
    );
  }

}


const mapStateToProps = (state) => ({
  mode              : selectors.mode(state),
  deviceMode        : selectors.deviceMode(state),
  calculationFormula: selectors.calculationFormula(state),
  aggregateBy       : selectors.aggregateBy(state),
  groupBy           : selectors.groupBy(state),
  printMode         : App.selectors.printMode(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSetMode              : (mode) => dispatch(actions.setMode(mode)),
  onSetDeviceMode        : (mode) => dispatch(actions.setDeviceMode(mode)),
  onSetCalculationFormula: (calculationFormula) => dispatch(actions.setCalculationFormula(calculationFormula)),
  onSetAggregateBy       : (aggregateBy) => dispatch(actions.setAggregateBy(aggregateBy)),
  onSetGroupBy           : (groupBy) => dispatch(actions.setGroupBy(groupBy)),
});


const ConnectedCgmTrendChartWidget = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CgmTrendChartWidget));


export default ConnectedCgmTrendChartWidget;
