import { FormattedMessage } from 'react-intl';
import React from 'react';
import InjectionIcon from 'svg/injection.svg';
import MealIcon from 'svg/meal.svg';
import ActivityIcon from 'svg/activity.svg';
import PillsIcon from 'svg/pills.svg';
import tooltipMessages from 'components/RelatedData/messages';
import { TooltipType } from '../types';
import messages from '../../../../messages';
import RelatedData from './RelatedData';
import Measurement from './Measurement';


export const getTooltipComponent = (tooltipType: TooltipType) => {
  let TooltipComponent = null;
  switch (tooltipType) {
    case 'measurement': {
      TooltipComponent = Measurement;
      break;
    }
    case 'related':
      TooltipComponent = RelatedData;
      break;
    default:
      TooltipComponent = null;
  }

  return TooltipComponent;
};

export const getLabel = (element, tooltipType) => {

  const navLabel = (tooltipType) === 'related'
    ? <FormattedMessage {...tooltipMessages[element.type].label} />
    : <FormattedMessage {...messages.headers.measurement} />;

  let icon = null;
  switch (element.type) {
    case 'food':
      icon = <MealIcon />;
      /* icon = element.isSnaqImport
        ? <img src="/assets/svg/snaq.svg" alt="snaq.io" className={styles.mealTooltip__snaqIcon} />
        : <MealIcon className={styles.mealTooltip__mealIcon} />; */
      break;
    case 'activity':
      icon = <ActivityIcon />;
      break;
    case 'pill':
      icon = <PillsIcon />;
      break;
    case 'injection':
      icon = <InjectionIcon />;
      break;
    default:
      icon = null;
  }


  return <><div>{ icon }</div>{ navLabel }</>;
};
