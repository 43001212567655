import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import deepForceUpdate from 'react-deep-force-update';
import { replace } from 'redux-first-routing';
import Cookies from 'universal-cookie';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import queryString from 'query-string';
import 'react-dates/initialize';
import ReactTooltip from 'react-tooltip';
import forEach from 'lodash/forEach';

import { AppContext } from 'context';
import router from 'router';
import configureStore from 'store/configureStore';
import { getCoreSagas } from 'store/sagas';
import appInsights from 'helpers/appInsights';
import { getAsyncInjectors } from 'helpers/asyncInjectors';
import createFetch from 'helpers/createFetch';
import createFileDownloadChannel from 'helpers/createFileDownloadChannel';
import createUploadFileChannel from 'helpers/createFileUploadChannel';
import history from 'helpers/history';
import { translateURL } from 'helpers/urlTools';
import clearStorages from 'helpers/clearStorages';
import OidcService from 'services/OidcService';
import {
  setClientIsInitialized,
  setRoute,
  setLocale,
  loadGTM,
  loadHotjar,
  pushVirtualPageview,
} from 'modules/App/actions';
import {
  // getMe,
  getPassphrase,
  fetchToken,
} from 'modules/Account/actions';
import globalStyles from 'global.pcss';
import SessionStorage from 'libs/SessionStorage';
import LocalStorage from './libs/LocalStorage';


window.alert = () => {}; // disable all low-level alerts

// -----------------------------------------------------------------------------

// Cookies
const cookies = new Cookies();

// Styles
const insertCss = (...styles) => {
  const allStyles = [globalStyles, ...styles];
  const removeCss = allStyles.map((style) => style._insertCss());
  return () => removeCss.forEach((dispose) => dispose());
};

// Reverse routing
const getUrl = translateURL(router);

// eslint-disable-next-line no-restricted-globals
const fetch = createFetch(self.fetch, {
  baseUrl: window.App.apiUrl,
});

const downloadFileChannel = createFileDownloadChannel(window.App.apiUrl);
const uploadFileChannel = createUploadFileChannel(window.App.apiUrl);

// Redirect
const redirect = (url) => history.replace(url);
const externalRedirect = (url) => {
  window.location.href = url;
};

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  domain        : window.App.domain,
  downloader    : window.App.downloader,
  bls           : window.App.bls,
  cookies,
  fetch,
  getUrl,
  redirect,
  externalRedirect,
  apiUrl        : window.App.apiUrl,
  assetUrl      : window.App.assetUrl,
  client        : window.App.client,
  currentVersion: window.App.currentVersion,
  releaseDate   : window.App.releaseDate,
  changelogUrl  : window.App.changelogUrl,
};

let stateSource;
if (history.location.pathname !== '/reports') {
  stateSource = window.App.state;
} else {
  const stateSourceRaw = LocalStorage.getItem('dumpedState');
  if (!stateSourceRaw) {
    stateSource = window.App.state;
  } else {
    stateSource = JSON.parse(LocalStorage.getItem('dumpedState'));
    // LocalStorage.removeItem('dumpedState');
  }
}

const store = configureStore(
  stateSource,
  {
    ...context,
    downloadFileChannel,
    uploadFileChannel,
    signalRUrl: window.App.signalRUrl,
    wsApiUrl  : window.App.wsApiUrl,
    apps      : window.App.apps,
  },
  history,
);

const container = document.getElementById('app');
let currentLocation = history.location;
let previousName = '';
let previousParams = {};
let isNameChanged = false;
let appInstance;

const scrollPositionsHistory = {};

if (currentLocation.pathname !== '/auth-silent' && currentLocation.pathname !== '/reports') {
  const { injectSagas } = getAsyncInjectors(store);
  forEach(getCoreSagas(), (saga, name) => injectSagas(name, saga));
  Promise.all([
    store.dispatch(setLocale(store.getState().app.locale)),
    store.dispatch(loadGTM()),
    store.dispatch(loadHotjar()),
    OidcService.clearStaleState(),
  ]);

  store.dispatch(fetchToken());
  if (cookies.get('hash')) store.dispatch(getPassphrase());
}


if (currentLocation.pathname === '/reports') {
  const { injectSagas } = getAsyncInjectors(store);
  forEach(getCoreSagas(), (saga, name) => injectSagas(name, saga));
  store.dispatch(setLocale(store.getState().app.locale));
  store.dispatch(loadGTM());
  store.dispatch(loadHotjar());
}


function scrollToHash(location, scrollX, scrollY) {
  const targetHash = location.hash.substr(1);
  if (targetHash) {
    const target = document.getElementById(targetHash);
    if (target) {
      scrollY = window.pageYOffset + target.getBoundingClientRect().top;
      window.scrollTo(scrollX, scrollY);
    }
  }
}


// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  const user = await OidcService.getUser();
  // pathname '/' === '/auth'
  if (!user && location.pathname !== '/' && location.pathname !== '/auth' && location.pathname !== '/reports') {
    SessionStorage.setItem('returnPathname', `${location.pathname}${location.search}${location.hash}`);
    clearStorages(true);
    await OidcService.login();
  }
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const isInitialRender = !action;
  try {
    context.pathname = location.pathname;
    context.query = queryString.parse(location.search);

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve({ ...context, store });

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    store.dispatch(pushVirtualPageview(location));

    if (route.redirect) {
      store.dispatch(setClientIsInitialized());
      store.dispatch(replace(route.redirect));
      return;
    }

    store.dispatch(setRoute({
      name  : route.name,
      ...(action ? { action } : null),
      params: route.params || {},
      previousName,
      previousParams,
      hash  : location.hash,
    }));
    isNameChanged = previousName !== (route.name || '');
    previousName = route.name || '';
    previousParams = route.params || {};

    appInsights.trackPageView({
      name      : route.name,
      properties: { routeParams: route.params || {} },
    });

    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;

    appInstance = renderReactApp(
      <Provider store={store}>
        <StyleContext.Provider value={{ insertCss }}>
          <AppContext.Provider value={context}>
            { route.component }
          </AppContext.Provider>
        </StyleContext.Provider>
      </Provider>,
      container,
      () => {
        let scrollX = 0;
        let scrollY = 0;

        if (isInitialRender) {
          // Switch off the native scroll restoration behavior and handle it manually
          // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
          if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
          }

          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);
          store.dispatch(setClientIsInitialized());
          scrollToHash(location, scrollX, scrollY);
          return;
        }

        const pos = scrollPositionsHistory[location.key];
        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          setTimeout(() => scrollToHash(location, scrollX, scrollY), 200);
        }

        // Restore the scroll position if it was saved into the state
        // or scroll to the given #hash anchor
        // or scroll to top of the page
        if (action !== 'REPLACE' || (location.state && !location.state.preserveScroll)) {
          window.scrollTo(scrollX, scrollY);
        }

        if (isNameChanged) {
          ReactTooltip.rebuild();
        }
      },
    );
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      console.error('RSK will reload your page after error');
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange(currentLocation);

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }

    onLocationChange(currentLocation);
  });
}
