exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".p_1EJ{position:relative;padding:0 3rem;margin-bottom:13px}.O_esB{width:20px;width:2rem;height:20px;height:2rem;border-radius:.4rem;position:absolute;left:0}.kO3Ot{max-width:200px;font-weight:700;font-size:14px;font-size:1.4rem;line-height:18px;line-height:1.8rem;overflow:hidden;white-space:break-spaces;-o-text-overflow:ellipsis;text-overflow:ellipsis;color:#0a385a;text-align:center}.kO3Ot:first-letter{text-transform:capitalize}", ""]);

// exports
exports.locals = {
	"tooltipTitle": "p_1EJ",
	"tooltipTitle__snaqIcon": "O_esB",
	"tooltipTitle__titleText": "kO3Ot"
};