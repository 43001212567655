exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1N1se{height:16px;width:10px}._1cntL{gap:8px;gap:.8rem}._1OOAJ{width:16px;width:1.6rem;height:16px;height:1.6rem}._1eFa2{fill:#f7d261}._7lB43{fill:#1ea98c}._2Bcc5{fill:#f9707e}._2XlrP{font-weight:700;font-size:12px;font-size:1.2rem;line-height:18px;line-height:1.8rem;white-space:nowrap;color:#0a385a}._3VZ0K,._2uVNb{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}._3VZ0K{font-size:12px;font-size:1.2rem;line-height:16px;line-height:1.6rem;white-space:nowrap;color:#6f7ea2;padding:8px 0}", ""]);

// exports
exports.locals = {
	"measurement__droplet": "_1N1se",
	"measurement__result": "_1cntL",
	"measurement__resultIcon": "_1OOAJ",
	"measurement__resultIcon--high": "_1eFa2",
	"measurement__resultIcon--normal": "_7lB43",
	"measurement__resultIcon--low": "_2Bcc5",
	"measurement__resultText": "_2XlrP",
	"measurement__datetime": "_3VZ0K",
	"measurement__value": "_2uVNb"
};