exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2G_xY{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}@keyframes _2G_xY{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}._3q1A3{width:24px;height:24px;padding:.2rem;background:#f5f9ff;border-radius:50%;position:relative}._3q1A3 svg{width:16px;width:1.6rem;height:16px;height:1.6rem;fill:#30a8ff;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}", ""]);

// exports
exports.locals = {
	"relatedData": "_3q1A3",
	"fading": "_2G_xY"
};