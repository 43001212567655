import get from 'lodash/get';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import { registerAction, unregisterAction } from 'helpers/reducerTools';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


const initialState = {
  deviceData      : null,
  deviceDataType  : null,
  status          : constants.DOWNLOADER_STATUSES.INITIALIZED,
  connectorType   : null,
  connectionId    : null,
  connectionStatus: null,
  connectionError : null,
  downloader      : {
    apiVersion           : null,
    mdaVersion           : null,
    bleAvailable         : true,
    bleAvailabilityReason: 'Compliant',
    devices              : [],
    driver               : {
      installationState    : constants.DRIVER_STATUSES.NOT_STARTED,
      isDriverInstalled    : false,
      lastInstallationError: null,
    },
  },
  scc: {
    machineId  : null,
    pairingCode: null,
  },
  fetching: [],
  sending : [],
  errors  : [],
};


export default function reducer(state = { ...initialState }, action = {}) {

  switch (action.type) {

    case actionTypes.CHECK_STATUS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.CHECK_STATUS),
        errors  : unregisterAction(state.errors, actionTypes.CHECK_STATUS),
      };
    }
    case actionTypes.INSTALL_DOWNLOADER: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.INSTALL_DOWNLOADER),
      };
    }
    case actionTypes.CHECK_STATUS_SUCCESS:
    case actionTypes.INSTALL_DOWNLOADER_SUCCESS: {
      const { status } = action.payload.downloader;
      const downloader = pick(action.payload.downloader, keys(initialState.downloader));
      return {
        ...state,
        status,
        downloader: { ...state.downloader, ...downloader },
        fetching  : unregisterAction(state.fetching, actionTypes.CHECK_STATUS, actionTypes.INSTALL_DOWNLOADER),
      };
    }
    case actionTypes.INSTALL_DOWNLOADER_CANCEL:
    case actionTypes.INSTALL_DOWNLOADER_TIMEOUT: {
      const { status } = action.payload;
      return {
        ...state,
        status,
        fetching: unregisterAction(state.fetching, actionTypes.INSTALL_DOWNLOADER),
      };
    }
    case actionTypes.CHECK_STATUS_ERROR: {
      const downloader = { ...state.downloader, ...action.payload.downloader };
      return {
        ...state,
        downloader,
        status  : constants.DOWNLOADER_STATUSES.NO_CONNECTION,
        fetching: unregisterAction(state.fetching, actionTypes.CHECK_STATUS),
        errors  : registerAction(state.errors, actionTypes.CHECK_STATUS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_DOWNLOADER: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.UPDATE_DOWNLOADER),
        errors  : unregisterAction(state.errors, actionTypes.UPDATE_DOWNLOADER),
      };
    }
    case actionTypes.UPDATE_DOWNLOADER_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.UPDATE_DOWNLOADER),
      };
    }
    case actionTypes.UPDATE_DOWNLOADER_ERROR:
    case actionTypes.UPDATE_DOWNLOADER_BAD_REQUEST_ERROR:
    case actionTypes.UPDATE_DOWNLOADER_ENDPOINT_NOT_FOUND_ERROR: {
      const { status } = action.payload;
      return {
        ...state,
        status,
        fetching: unregisterAction(state.fetching, actionTypes.UPDATE_DOWNLOADER),
        errors  : registerAction(state.errors, actionTypes.UPDATE_DOWNLOADER),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CHECKING_CONNECTION_START: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.CHECKING_CONNECTION_START),
        errors  : unregisterAction(state.errors, actionTypes.CHECKING_CONNECTION_START),
      };
    }

    case actionTypes.CHECKING_CONNECTION_SET:
    case actionTypes.CHECKING_CONNECTION_STOP: {
      const { connectionStatus, clearConnection } = action.payload;
      return {
        ...state,
        ...(
          clearConnection
            ? { connectionId: null, connectionStatus: null, connectionError: null }
            : { connectionStatus }
        ),
        fetching: unregisterAction(state.fetching, actionTypes.CHECKING_CONNECTION_START),
      };
    }

    case actionTypes.CHECKING_CONNECTION_ERROR: {
      const { error } = action;
      const businessError = error.getBusinessError && error.getBusinessError();
      const connectionError = businessError ? businessError.code : null;
      const connectionStatus = constants.CONNECTION_STATUSES.ERROR;
      return {
        ...state,
        connectionStatus,
        connectionError,
        fetching: unregisterAction(state.fetching, actionTypes.CHECKING_CONNECTION_START),
        errors  : registerAction(state.errors, actionTypes.CHECKING_CONNECTION_START),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CLEAR_CONNECTION: {
      return {
        ...state,
        connectionId    : null,
        connectionStatus: null,
        connectionError : null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GET_DEVICE_DATA: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.GET_DEVICE_DATA),
      };
    }

    case actionTypes.GET_DEVICE_DATA_SUCCESS: {
      const { deviceData } = action.payload;
      return {
        ...state,
        deviceData,
        fetching: unregisterAction(state.fetching, actionTypes.GET_DEVICE_DATA),
      };
    }

    case actionTypes.GET_DEVICE_DATA_ERROR: {
      const { error } = action;
      const businessError = error.getBusinessError && error.getBusinessError();
      const connectionError = businessError ? businessError.code : null;
      const connectionStatus = constants.CONNECTION_STATUSES.ERROR;
      return {
        ...state,
        connectionStatus,
        connectionError,
        fetching: unregisterAction(state.fetching, actionTypes.GET_DEVICE_DATA),
        errors  : registerAction(state.errors, actionTypes.GET_DEVICE_DATA),
      };
    }

    case actionTypes.SET_DEVICE_DATA_FOR_PREVIEW: {
      // TODO: Preview needs deviceData. See routes/preview-results
      const { deviceData } = action.payload;
      return {
        ...state,
        deviceData,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CHECK_BLUE_CABLE_DRIVER: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.CHECK_BLUE_CABLE_DRIVER),
      };
    }

    case actionTypes.CHECK_BLUE_CABLE_DRIVER_SUCCESS: {
      const { driver } = action.payload;
      const downloader = { ...state.downloader, driver };
      return {
        ...state,
        downloader,
        fetching: unregisterAction(state.fetching, actionTypes.CHECK_BLUE_CABLE_DRIVER),
      };
    }

    case actionTypes.CHECK_BLUE_CABLE_DRIVER_ERROR: {
      const downloader = { ...state.downloader, driver: { ...initialState.driver } };
      return {
        ...state,
        downloader,
        fetching: unregisterAction(state.fetching, actionTypes.CHECK_BLUE_CABLE_DRIVER),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.LISTENING_BLUETOOTH_START: {
      const deviceDataType = get(action, 'payload.deviceDataType', constants.DEVICE_DATA_TYPES.BGM);
      return {
        ...state,
        deviceDataType,
        fetching: registerAction(state.fetching, actionTypes.LISTENING_BLUETOOTH_START),
        errors  : unregisterAction(state.errors, actionTypes.LISTENING_BLUETOOTH_START),
      };
    }

    case actionTypes.LISTENING_BLUETOOTH_STOP: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.LISTENING_BLUETOOTH_START),
      };
    }

    case actionTypes.LISTENING_BLUETOOTH_ERROR: {
      const status = constants.DOWNLOADER_STATUSES.NO_CONNECTION;
      return {
        ...state,
        status,
        fetching: unregisterAction(state.fetching, actionTypes.LISTENING_BLUETOOTH_START),
        errors  : registerAction(state.errors, actionTypes.LISTENING_BLUETOOTH_START),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_AVAILABLE_DEVICES: {
      const { devices } = action.payload;
      const downloader = { ...state.downloader, devices };
      return {
        ...state,
        downloader,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SEND_BLUETOOTH_PIN: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.SEND_BLUETOOTH_PIN),
        errors : unregisterAction(state.errors, actionTypes.SEND_BLUETOOTH_PIN),
      };
    }

    case actionTypes.SEND_BLUETOOTH_PIN_SUCCESS: {
      const { connectionId, connectionStatus } = action.payload;
      return {
        ...state,
        connectorType: constants.CONNECTOR_TYPES.MDA,
        connectionId,
        connectionStatus,
        sending      : unregisterAction(state.sending, actionTypes.SEND_BLUETOOTH_PIN),
      };
    }

    case actionTypes.SEND_BLUETOOTH_PIN_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SEND_BLUETOOTH_PIN),
        errors : registerAction(state.errors, actionTypes.SEND_BLUETOOTH_PIN),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CONNECT_BLUE_CABLE:
    case actionTypes.CONNECT_BLUETOOTH:
    case actionTypes.CONNECT_USB:
    case actionTypes.CONNECT_USB_C:
    case actionTypes.CONNECT_SCC: {
      const deviceDataType = get(action, 'payload.deviceDataType', constants.DEVICE_DATA_TYPES.BGM);
      return {
        ...state,
        connectorType   : null,
        connectionId    : null,
        connectionStatus: null,
        connectionError : null,
        deviceData      : null,
        deviceDataType,
        fetching        : unregisterAction(state.fetching, actionTypes.LISTENING_BLUETOOTH_START),
        sending         : registerAction(state.sending, actionTypes.CONNECT),
        errors          : unregisterAction(state.errors, actionTypes.CONNECT),
      };
    }

    case actionTypes.CONNECT_SUCCESS: {
      const { connectorType, connectionId, connectionStatus } = action.payload;
      return {
        ...state,
        connectorType,
        connectionId,
        connectionStatus,
        sending: unregisterAction(state.sending, actionTypes.CONNECT),
      };
    }

    case actionTypes.CONNECT_ERROR: {
      const { error } = action;
      const businessError = error.getBusinessError && error.getBusinessError();
      const connectionError = businessError ? businessError.code : null;
      const connectionStatus = constants.CONNECTION_STATUSES.ERROR;
      return {
        ...state,
        connectionStatus,
        connectionError,
        sending: unregisterAction(state.sending, actionTypes.CONNECT),
        errors : registerAction(state.errors, actionTypes.CONNECT),
      };
    }

    // SCC -------------------------------------------------------------------------------------------------------------

    case actionTypes.CHECK_SCC_STATUS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.CHECK_SCC_STATUS),
        errors  : unregisterAction(state.errors, actionTypes.CHECK_SCC_STATUS),
      };
    }
    case actionTypes.CHECK_SCC_STATUS_SUCCESS: {
      const { status, machineId } = action.payload;
      const scc = { ...state.scc, machineId };
      return {
        ...state,
        status,
        scc,
        fetching: unregisterAction(state.fetching, actionTypes.CHECK_SCC_STATUS),
      };
    }
    case actionTypes.CHECK_SCC_STATUS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.CHECK_SCC_STATUS),
        errors  : registerAction(state.errors, actionTypes.CHECK_SCC_STATUS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.PAIR_SCC: {
      const scc = { ...state.scc, pairingCode: null };
      return {
        ...state,
        scc,
        fetching: registerAction(state.fetching, actionTypes.PAIR_SCC),
        errors  : unregisterAction(state.errors, actionTypes.PAIR_SCC),
      };
    }

    case actionTypes.PAIR_SCC_SET_CODE: {
      const { pairingCode } = action.payload;
      const scc = { ...state.scc, pairingCode };
      return {
        ...state,
        scc,
      };
    }

    case actionTypes.PAIR_SCC_SUCCESS: {
      const { machineId } = action.payload;
      const scc = { ...state.scc, machineId, pairingCode: null };
      return {
        ...state,
        scc,
        fetching: unregisterAction(state.fetching, actionTypes.PAIR_SCC),
      };
    }

    case actionTypes.PAIR_SCC_CANCEL: {
      const scc = { ...state.scc, pairingCode: null };
      return {
        ...state,
        scc,
        fetching: unregisterAction(state.fetching, actionTypes.PAIR_SCC),
      };
    }

    case actionTypes.PAIR_SCC_ERROR: {
      const scc = { ...state.scc, pairingCode: null };
      return {
        ...state,
        scc,
        fetching: unregisterAction(state.fetching, actionTypes.PAIR_SCC),
        errors  : registerAction(state.errors, actionTypes.PAIR_SCC),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.GET_SERIAL_NUMBER_TOKEN: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.GET_SERIAL_NUMBER_TOKEN),
        errors  : unregisterAction(state.errors, actionTypes.GET_SERIAL_NUMBER_TOKEN),
      };
    }

    case actionTypes.GET_SERIAL_NUMBER_TOKEN_SUCCESS: {
      const { serialNumber, serialNumberToken } = action.payload;
      const { deviceData } = state;
      if (!deviceData || deviceData.serialNumber !== serialNumber) {
        return state;
      }
      return {
        ...state,
        deviceData: { ...deviceData, deviceSerialNumberToken: serialNumberToken },
        fetching  : unregisterAction(state.fetching, actionTypes.GET_SERIAL_NUMBER_TOKEN),
      };
    }

    case actionTypes.GET_SERIAL_NUMBER_TOKEN_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.GET_SERIAL_NUMBER_TOKEN),
        errors  : registerAction(state.errors, actionTypes.GET_SERIAL_NUMBER_TOKEN),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
