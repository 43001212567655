exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3WVrd{display:-ms-flexbox;display:flex;gap:8px;padding:12px 0;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-ms-flex-pack:distribute;justify-content:space-around}._2o91W{height:2px;background-color:#e0e8f2;border-radius:1px;width:100%}._15YJY{background-color:#30a8ff}", ""]);

// exports
exports.locals = {
	"dots": "_3WVrd",
	"dots__dot": "_2o91W",
	"dots__dot--active": "_15YJY"
};