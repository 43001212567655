import React, { FC, ReactElement } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cn from 'classnames';
import Chevron from 'svg/chevron-right.svg';
import styles from './Nav.pcss';


interface NavProps {
  onIndexChange: (newIndex: number) => void;
  measurmentsCount: number;
  currentIndex: number;
  label: ReactElement;
}

const Nav:FC<NavProps> = ({ currentIndex, onIndexChange, measurmentsCount, label }) => {
  useStyles(styles);

  const onChangeIndex = (newIndex: number) => {
    onIndexChange(newIndex);
  };

  const onNavClickHandler = (e: React.SyntheticEvent<EventTarget>) => {
    if (!(e.currentTarget instanceof HTMLDivElement)) {
      return;
    }
    const { direction } = e.currentTarget.dataset;
    switch (direction) {
      case 'prev': {
        if (currentIndex - 1 < 0) {
          onChangeIndex(measurmentsCount - 1);
        } else {
          onChangeIndex(currentIndex - 1);
        }
        break;
      }
      case 'next': {
        if (currentIndex + 1 > measurmentsCount - 1) {
          onChangeIndex(0);
        } else {
          onChangeIndex(currentIndex + 1);
        }
        break;
      }
      default:
        onChangeIndex(0);
    }
  };

  const renderButton = (direction: string, classes = '') => (
    <div
      onKeyDown={onNavClickHandler}
      data-direction={direction}
      onClick={onNavClickHandler}
      className={cn(styles.nav__navbutton, classes)}
    >
      <Chevron />
    </div>
  );

  return (
    <nav className={styles.nav}>
      { renderButton('prev', styles['nav__navbutton--left']) }
      <div className={styles.nav__label}>{ label }</div>
      { renderButton('next') }
    </nav>
  );
};

export default Nav;
