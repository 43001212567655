import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import Widget from 'components/Widget';
import NavTabs from 'components/NavTabs';
import ImportsHistory from '../ImportsHistory';
import ReadingsTable from '../ReadingsTable';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from './MeasurementsTableWidget.pcss';


class MeasurementsTableWidget extends React.PureComponent {

  static propTypes = {
    // Explicit props
    phiSet    : PropTypes.object,
    conversion: PropTypes.object.isRequired,
    standards : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }).isRequired,
    readings              : PropTypes.array,
    timeSeriesResources   : PropTypes.array,
    relatedData           : PropTypes.array,
    customRanges          : PropTypes.array,
    isInProgress          : PropTypes.bool,
    isMyDownloadsHidden   : PropTypes.bool,
    isReadOnly            : PropTypes.bool,
    activeReadingTimestamp: PropTypes.number,
    className             : PropTypes.string,
    // Explicit actions
    onCellClick           : PropTypes.func,
  };

  static defaultProps = {
    className: '',
  }


  constructor(props) {
    super(props);
    this.state = {
      activeWidget: 'MeasurementsTable',
    };
  }


  onChangeWidget(activeWidget) {
    this.setState({ activeWidget });
  }

  get navTabsItems() {
    const navTabsItems = [];
    navTabsItems.push(
      {
        label: messages.headers.measurementsTable,
        value: 'MeasurementsTable',
      },
    );
    if (!this.props.isMyDownloadsHidden) {
      navTabsItems.push(
        {
          label: messages.headers.myDownloads,
          value: 'ImportsHistory',
        },
      );
    }
    return navTabsItems;
  }


  renderNavTabs() {
    if (this.props.isReadOnly) {
      return null;
    }
    return (
      <div className={styles.navWrapper}>
        <NavTabs
          items={this.navTabsItems}
          activeItemValue={this.state.activeWidget}
          onClick={(value) => this.onChangeWidget(value)}
        />
      </div>
    );
  }


  renderWidget() {
    const { activeWidget } = this.state;
    if (activeWidget === 'ImportsHistory') {
      return <ImportsHistory phiSet={this.props.phiSet} />;
    }
    return (
      <ReadingsTable
        conversion={this.props.conversion}
        standards={this.props.standards}
        readings={this.props.readings}
        timeSeriesResources={this.props.timeSeriesResources}
        relatedData={this.props.relatedData}
        customRanges={this.props.customRanges}
        activeReadingTimestamp={this.props.activeReadingTimestamp}
        isReadOnly={this.props.isReadOnly}
        isInProgress={this.props.isInProgress}
        onCellClick={this.props.onCellClick}
      />
    );
  }


  render() {
    return (
      <Widget
        widgetId={constants.MEASUREMENTS_TABLE_WIDGET}
        key="MeasurementsTableWidget"
        isNoHeader
        className={this.props.className}
      >
        { this.renderNavTabs() }
        { this.renderWidget() }
      </Widget>
    );
  }

}

export default withStyles(styles)(MeasurementsTableWidget);
