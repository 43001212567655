export default {

  headers: {
    myDownloads              : { id: 'patientResults.headers.myDownloads' },
    myResults                : { id: 'patientResults.headers.myResults' },
    bloodGlucoseConcentration: { id: 'patientResults.headers.bloodGlucoseConcentration' },
    measurementsTable        : { id: 'patientResults.headers.measurementsTable' },
    measurement              : { id: 'patientResults.headers.measurement' },
    seriesOfMeasurements     : { id: 'patientResults.headers.seriesOfMeasurements' },
    glucoseLevelDistribution : { id: 'patientResults.headers.glucoseLevelDistribution' },
    resultsSummary           : { id: 'patientResults.headers.resultsSummary' },
    bgm                      : { id: 'patientResults.headers.bgm' },
    cgm                      : { id: 'patientResults.headers.cgm' },
    timeInRanges             : { id: 'patientResults.headers.timeInRanges' },
    cgmTimeInRanges          : { id: 'patientResults.headers.cgmTimeInRanges' },
    ambulatoryGlucoseProfile : { id: 'patientResults.headers.ambulatoryGlucoseProfile' },
    glucoseStatisticAndTarget: { id: 'patientResults.headers.glucoseStatisticAndTarget' },
    modalDay                 : { id: 'patientResults.headers.modalDay' },
    dailyGlucoseProfile      : { id: 'patientResults.headers.dailyGlucoseProfile' },
    additionalMeasurements   : { id: 'patientResults.headers.additionalMeasurements' },
    previewingDataFromMeter  : { id: 'patientResults.headers.previewingDataFromMeter' },
    patientsGestationalReport: { id: 'patientResults.headers.patientsGestationalReportTitle' },
  },

  buttons: {
    additionalMeasurements   : { id: 'patientResults.buttons.additionalMeasurements' },
    downloadPdfReport        : { id: 'patientResults.buttons.downloadPdfReport' },
    generateReport           : { id: 'patientResults.buttons.generateReport' },
    invitePatient            : { id: 'patientResults.buttons.invitePatient' },
    joinPoints               : { id: 'patientResults.buttons.joinPoints' },
    showMore                 : { id: 'patientResults.buttons.showMore' },
    showLess                 : { id: 'patientResults.buttons.showLess' },
    aggregated               : { id: 'patientResults.buttons.aggregated' },
    grouped                  : { id: 'patientResults.buttons.grouped' },
    all                      : { id: 'patientResults.buttons.all' },
    submit                   : { id: 'patientResults.buttons.submit' },
    cancel                   : { id: 'patientResults.buttons.cancel' },
    standard                 : { id: 'patientResults.buttons.standard' },
    agpcgm                   : { id: 'patientResults.buttons.agpcgm' },
    cgm                      : { id: 'patientResults.buttons.cgm' },
    bgm                      : { id: 'patientResults.buttons.bgm' },
    standardDashboardReport  : { id: 'patientResults.buttons.standardDashboardReport' },
    AgpBgmDashboardReport    : { id: 'patientResults.buttons.AgpBgmDashboardReport' },
    AgpCgmDashboardReport    : { id: 'patientResults.buttons.AgpCgmDashboardReport' },
    CapturAgpCgmReport       : { id: 'patientResults.buttons.CapturAgpCgmReport' },
    refresh                  : { id: 'patientResults.buttons.refresh' },
    editPatient              : { id: 'patientResults.buttons.editPatient' },
    bloodSugarReport         : { id: 'patientResults.buttons.bloodSugarReport' },
    bloodSugarDiaryReport    : { id: 'patientResults.buttons.bloodSugarDiaryReport' },
    visitHistory             : { id: 'patientResults.buttons.visitHistory' },
    invite                   : { id: 'patientResults.buttons.invite' },
    exportToCSV              : { id: 'patientResults.buttons.exportToCSV' },
    PatientsBgData           : { id: 'patientResults.buttons.PatientsBgData' },
    resendInvitation         : { id: 'patientResults.buttons.resendInvitation' },
    addPatientToFavorite     : { id: 'patientResults.buttons.addPatientToFavorite' },
    removePatientFromFavorite: { id: 'patientResults.buttons.removePatientFromFavorite' },
    close                    : { id: 'patientResults.buttons.close' },
    saveResult               : { id: 'patientResults.buttons.saveResult' },
    viewSelected             : { id: 'patientResults.buttons.viewSelected' },
  },

  abbr: {
    day: { id: 'patientResults.abbr.day' },
    avg: { id: 'patientResults.abbr.avg' },
  },

  presets: {
    days      : { id: 'patientResults.presets.days' },
    months    : { id: 'patientResults.presets.months' },
    all       : { id: 'patientResults.presets.all' },
    newResults: { id: 'patientResults.presets.newResults' },
  },

  formulas: {
    none         : { id: 'patientResults.formulas.none' },
    mean         : { id: 'patientResults.formulas.mean' },
    medianStd    : { id: 'patientResults.formulas.median+std' },
    hyperglycemia: { id: 'patientResults.formulas.hyperglycemia' },
    hypoglycemia : { id: 'patientResults.formulas.hypoglycemia' },
    count        : { id: 'patientResults.formulas.count' },
    bins         : { id: 'patientResults.formulas.bins' },
  },

  aggregateBy: {
    day  : { id: 'patientResults.aggregateBy.day' },
    week : { id: 'patientResults.aggregateBy.week' },
    month: { id: 'patientResults.aggregateBy.month' },
  },

  groupBy: {
    weekday    : { id: 'patientResults.groupBy.weekday' },
    month      : { id: 'patientResults.groupBy.month' },
    quarter    : { id: 'patientResults.groupBy.quarter' },
    hours_range: { id: 'patientResults.groupBy.hours_range' },
    hours      : { id: 'patientResults.groupBy.hours' },
  },

  chartLabels: {
    week   : { id: 'patientResults.chartLabels.week' },
    quarter: { id: 'patientResults.chartLabels.quarter' },
  },

  momentFormats: {
    week   : { id: 'patientResults.momentFormats.week' },
    quarter: { id: 'patientResults.momentFormats.quarter' },
  },

  tableLabels: {
    total       : { id: 'patientResults.tableLabels.total' },
    median      : { id: 'patientResults.tableLabels.median' },
    stdDev      : { id: 'patientResults.tableLabels.stdDev' },
    highest     : { id: 'patientResults.tableLabels.highest' },
    lowest      : { id: 'patientResults.tableLabels.lowest' },
    timeOfImport: { id: 'patientResults.tableLabels.timeOfImport' },
    dateRange   : { id: 'patientResults.tableLabels.dateRange' },
    readings    : { id: 'patientResults.tableLabels.readings' },
    source      : { id: 'patientResults.tableLabels.source' },
    filterChart : { id: 'patientResults.tableLabels.filterChart' },
  },

  labels: {
    age                   : { id: 'patientResults.labels.age' },
    aggregateBy           : { id: 'patientResults.labels.aggregateBy' },
    calculationFormula    : { id: 'patientResults.labels.calculationFormula' },
    flagsFilters          : { id: 'patientResults.labels.flagsFilters' },
    groupBy               : { id: 'patientResults.labels.groupBy' },
    diabetesType          : { id: 'patientResults.labels.diabetesType' },
    treatmentType         : { id: 'patientResults.labels.treatmentType' },
    glucoseConcentration  : { id: 'patientResults.labels.glucoseConcentration' },
    creatingInvitation    : { id: 'patientResults.labels.creatingInvitation' },
    resendInvitation      : { id: 'patientResults.labels.resendInvitation' },
    inviteWithEmail       : { id: 'patientResults.labels.inviteWithEmail' },
    inviteWithoutEmail    : { id: 'patientResults.labels.inviteWithoutEmail' },
    synchronizing         : { id: 'patientResults.labels.synchronizing' },
    synchronized          : { id: 'patientResults.labels.synchronized' },
    unsynchronized        : { id: 'patientResults.labels.unsynchronized' },
    pendingSharingRequest : { id: 'patientResults.labels.pendingSharingRequest' },
    rejectedSharingRequest: { id: 'patientResults.labels.rejectedSharingRequest' },
    revokedSharingRequest : { id: 'patientResults.labels.revokedSharingRequest' },
    periodWithResults     : { id: 'patientResults.labels.periodWithResults' },
    presentationTypes     : { id: 'patientResults.labels.presentationTypes' },
    resultsFrom           : { id: 'patientResults.labels.resultsFrom' },
    resultsTo             : { id: 'patientResults.labels.resultsTo' },
    showLine              : { id: 'patientResults.labels.showLine' },
    chartView             : { id: 'patientResults.labels.chartView' },
    tableView             : { id: 'patientResults.labels.tableView' },
    unit                  : { id: 'patientResults.labels.unit' },
    withMeasurements      : { id: 'patientResults.labels.withMeasurements' },
    withoutMeasurements   : { id: 'patientResults.labels.withoutMeasurements' },
    measurementsPerDay    : { id: 'patientResults.labels.measurementsPerDay' },
    height                : { id: 'patientResults.labels.height' },
    weight                : { id: 'patientResults.labels.weight' },
    yearsOld              : { id: 'patientResults.labels.yearsOld' },
    veryHigh              : { id: 'patientResults.labels.veryHigh' },
    high                  : { id: 'patientResults.labels.high' },
    normal                : { id: 'patientResults.labels.normal' },
    low                   : { id: 'patientResults.labels.low' },
    veryLow               : { id: 'patientResults.labels.veryLow' },
    h                     : { id: 'patientResults.labels.h' },
    hours                 : { id: 'patientResults.labels.hours' },
    min                   : { id: 'patientResults.labels.min' },
    minutes               : { id: 'patientResults.labels.minutes' },
    insufficiencyInfo     : { id: 'patientResults.labels.insufficiencyInfo' },
    insufficiencyDatesInfo: { id: 'patientResults.labels.insufficiencyDatesInfo' },
    firstName             : { id: 'patientResults.labels.firstName' },
    lastName              : { id: 'patientResults.labels.lastName' },
    closePrintMode        : { id: 'patientResults.labels.closePrintMode' },
    avgConcentration      : { id: 'patientResults.labels.avgConcentration' },
    stdDev                : { id: 'patientResults.labels.stdDev' },
    mgmtIndicator         : { id: 'patientResults.labels.mgmtIndicator' },
    male                  : { id: 'patientResults.labels.male' },
    female                : { id: 'patientResults.labels.female' },
    email                 : { id: 'patientResults.labels.email' },
    phone                 : { id: 'patientResults.labels.phone' },
    otherInformation      : { id: 'patientResults.labels.otherInformation' },
    modalGlucoseLevelPwd  : { id: 'patientResults.labels.modalGlucoseLevelPwd' },
    ios                   : { id: 'patientResults.labels.ios' },
    android               : { id: 'patientResults.labels.android' },
    showRelatedData       : { id: 'patientResults.labels.showRelatedData' },
    avgBlood              : { id: 'patientResults.labels.avgBlood' },
    resendSharingRequest  : { id: 'patientResults.labels.resendSharingRequest' },
    unitMeasurmentsTable  : { id: 'patientResults.labels.unitMeasurmentsTable' },
    injectionUnit         : { id: 'patientResults.labels.injectionUnit' },
    food                  : { id: 'patientResults.labels.food' },
    foodUnit              : { id: 'patientResults.labels.foodUnit' },
    activity              : { id: 'patientResults.labels.activity' },
    activityUnit          : { id: 'patientResults.labels.activityUnit' },
    ofTotal               : { id: 'patientResults.labels.ofTotal' },
    checks                : { id: 'patientResults.labels.checks' },
    unitCount             : { id: 'patientResults.labels.unitCount' },
    count                 : { id: 'patientResults.labels.count' },
    pillsParam            : { id: 'patientResults.labels.pillsParam' },
    injection             : { id: 'patientResults.labels.injection' },
    injectionsParam       : { id: 'patientResults.labels.injectionsParam' },
    medications           : { id: 'patientResults.labels.medications' },
    milligramsParam       : { id: 'patientResults.labels.milligramsParam' },
    unitsParam            : { id: 'patientResults.labels.unitsParam' },
    noneParam             : { id: 'patientResults.labels.noneParam' },
    patientsBgDataReport  : {
      time               : { id: 'patientResults.labels.patientsBgDataReport.time' },
      value              : { id: 'patientResults.labels.patientsBgDataReport.value' },
      medicationLongValue: { id: 'patientResults.labels.patientsBgDataReport.medicationLongValue' },
      medicationFastValue: { id: 'patientResults.labels.patientsBgDataReport.medicationFastValue' },
      foodValue          : { id: 'patientResults.labels.patientsBgDataReport.foodValue' },
      mealMarker         : { id: 'patientResults.labels.patientsBgDataReport.mealMarker' },
    },
    bloodPressure         : { id: 'patientResults.labels.bloodPressure' },
    bmi                   : { id: 'patientResults.labels.bmi' },
    date                  : { id: 'patientResults.labels.date' },
    diastolicBloodPressure: { id: 'patientResults.labels.diastolicBloodPressure' },
    diastolicPressure     : { id: 'patientResults.labels.diastolicPressure' },
    latestValue           : { id: 'patientResults.labels.latestValue' },
    ldl                   : { id: 'patientResults.labels.ldl' },
    name                  : { id: 'patientResults.labels.name' },
    noData                : { id: 'patientResults.labels.noData' },
    recentResults         : { id: 'patientResults.labels.recentResults' },
    systolicBloodPressure : { id: 'patientResults.labels.systolicBloodPressure' },
    systolicPressure      : { id: 'patientResults.labels.systolicPressure' },
    time                  : { id: 'patientResults.labels.time' },
    triglycerides         : { id: 'patientResults.labels.triglycerides' },
  },

  noting: {
    clickItem : { id: 'patientResults.noting.clickItem' },
    clickLevel: { id: 'patientResults.noting.clickLevel' },
    clickPoint: { id: 'patientResults.noting.clickPoint' },
  },

  infos: {
    selectFamilyMember  : { id: 'patientResults.infos.selectFamilyMember' },
    editPatientModalKpi : { id: 'patientResults.infos.editPatientModalKpi' },
    kpiVeryHigh         : { id: 'patientResults.infos.kpiVeryHigh' },
    kpiVeryLow          : { id: 'patientResults.infos.kpiVeryLow' },
    kpiStandardDeviation: { id: 'patientResults.infos.kpiStandardDeviation' },
    kpiCV               : { id: 'patientResults.infos.kpiCV' },
    kpiGMI              : { id: 'patientResults.infos.kpiGMI' },
    kpiReadingsPerDay   : { id: 'patientResults.infos.kpiReadingsPerDay' },
    viewModeOnly        : { id: 'patientResults.infos.viewModeOnly' },
    provideOtherResults : { id: 'patientResults.infos.provideOtherResults' },
  },

  report: {
    info     : { id: 'patientResults.report.info' },
    for      : { id: 'patientResults.report.for' },
    generated: { id: 'patientResults.report.generated' },

    headers: {
      gestationalReportTitle: { id: 'patientResults.report.headers.gestationalReportTitle' },
    },

    labels: {
      date     : { id: 'patientResults.report.labels.date' },
      mealName : { id: 'patientResults.report.labels.mealName' },
      mealStart: { id: 'patientResults.report.labels.mealStart' },
    },

    meals: {
      night_am : { id: 'patientResults.report.meals.night_am' },
      breakfast: { id: 'patientResults.report.meals.breakfast' },
      lunch    : { id: 'patientResults.report.meals.lunch' },
      dinner   : { id: 'patientResults.report.meals.dinner' },
      night_pm : { id: 'patientResults.report.meals.night_pm' },
    },

    errors: {
      wrongOrder: { id: 'patientResults.report.errors.wrongOrder' },
    },
  },

  placeholders: {
    firstName       : { id: 'patientResults.placeholders.firstName' },
    lastName        : { id: 'patientResults.placeholders.lastName' },
    email           : { id: 'patientResults.placeholders.email' },
    phone           : { id: 'patientResults.placeholders.phone' },
    otherInformation: { id: 'patientResults.placeholders.otherInformation' },
  },

  nav: {
    personalInformation: { id: 'patientResults.nav.personalInformation' },
    healthInformation  : { id: 'patientResults.nav.healthInformation' },
    targetsInformation : { id: 'patientResults.nav.targetsInformation' },
    kpiInformation     : { id: 'patientResults.nav.kpiInformation' },
  },

  glucoseReport: {
    title                           : { id: 'patientResults.glucoseReport.title' },
    dateOfBirth                     : { id: 'patientResults.glucoseReport.dateOfBirth' },
    preMealRange                    : { id: 'patientResults.glucoseReport.preMealRange' },
    postMealRange                   : { id: 'patientResults.glucoseReport.postMealRange' },
    correctRange                    : { id: 'patientResults.glucoseReport.correctRange' },
    readings                        : { id: 'patientResults.glucoseReport.readings' },
    tableDays                       : { id: 'patientResults.glucoseReport.tableDays' },
    tableAutomaticReadings          : { id: 'patientResults.glucoseReport.tableAutomaticReadings' },
    tableManualReadings             : { id: 'patientResults.glucoseReport.tableManualReadings' },
    tableReadingsPerDay             : { id: 'patientResults.glucoseReport.tableReadingsPerDay' },
    tableAveragePreMeal             : { id: 'patientResults.glucoseReport.tableAveragePreMeal' },
    tableAveragePostMeal            : { id: 'patientResults.glucoseReport.tableAveragePostMeal' },
    tableStandardDeviation          : { id: 'patientResults.glucoseReport.tableStandardDeviation' },
    tableRange                      : { id: 'patientResults.glucoseReport.tableRange' },
    tableCV                         : { id: 'patientResults.glucoseReport.tableCV' },
    generatedReport                 : { id: 'patientResults.glucoseReport.generatedReport' },
    headerGlucoseLevelHeader        : { id: 'patientResults.glucoseReport.headerGlucoseLevelHeader' },
    headerBloodGlucoseHeader        : { id: 'patientResults.glucoseReport.headerBloodGlucoseHeader' },
    bloodGlucoseBottom              : { id: 'patientResults.glucoseReport.bloodGlucoseBottom' },
    headerBloodGlucoseHeaderPreMeal : { id: 'patientResults.glucoseReport.headerBloodGlucoseHeaderPreMeal' },
    bloodGlucoseBottomPreMeal       : { id: 'patientResults.glucoseReport.bloodGlucoseBottomPreMeal' },
    headerBloodGlucoseHeaderPostMeal: { id: 'patientResults.glucoseReport.headerBloodGlucoseHeaderPostMeal' },
    bloodGlucoseBottomPostMeal      : { id: 'patientResults.glucoseReport.bloodGlucoseBottomPostMeal' },
    textField                       : { id: 'patientResults.glucoseReport.textField' },
    criticalLow                     : { id: 'patientResults.glucoseReport.criticalLow' },
    criticalHigh                    : { id: 'patientResults.glucoseReport.criticalHigh' },
    low                             : { id: 'patientResults.glucoseReport.low' },
    high                            : { id: 'patientResults.glucoseReport.high' },
    diabetesType                    : { id: 'patientResults.glucoseReport.diabetesType' },
    treatmentType                   : { id: 'patientResults.glucoseReport.treatmentType' },
  },

  dailyReport: {
    title             : { id: 'patientResults.dailyReport.title' },
    legend            : { id: 'patientResults.dailyReport.legend' },
    legendInjection   : { id: 'patientResults.dailyReport.legendInjection' },
    legendManual      : { id: 'patientResults.dailyReport.legendManual' },
    legendDouble      : { id: 'patientResults.dailyReport.legendDouble' },
    fromDate          : { id: 'patientResults.dailyReport.fromDate' },
    toDate            : { id: 'patientResults.dailyReport.toDate' },
    readings          : { id: 'patientResults.dailyReport.readings' },
    averageDay        : { id: 'patientResults.dailyReport.averageDay' },
    daysWithoutReading: { id: 'patientResults.dailyReport.daysWithoutReading' },
    average           : { id: 'patientResults.dailyReport.average' },
    high              : { id: 'patientResults.dailyReport.high' },
    low               : { id: 'patientResults.dailyReport.low' },
    deviation         : { id: 'patientResults.dailyReport.deviation' },
    averagePreMeal    : { id: 'patientResults.dailyReport.averagePreMeal' },
    averagePostMeal   : { id: 'patientResults.dailyReport.averagePostMeal' },
    averageOverall    : { id: 'patientResults.dailyReport.averageOverall' },
    tableCriticalHigh : { id: 'patientResults.dailyReport.tableCriticalHigh' },
    tablePostMeal     : { id: 'patientResults.dailyReport.tablePostMeal' },
    tablePreMeal      : { id: 'patientResults.dailyReport.tablePreMeal' },
    tableCriticalLow  : { id: 'patientResults.dailyReport.tableCriticalLow' },
    standardDay       : { id: 'patientResults.dailyReport.standardDay' },
  },

  kpi: {
    GMI              : { id: 'patientResults.kpi.GMI' },
    veryLow          : { id: 'patientResults.kpi.veryLow' },
    veryHigh         : { id: 'patientResults.kpi.veryHigh' },
    standardDeviation: { id: 'patientResults.kpi.standardDeviation' },
    average          : { id: 'patientResults.kpi.average' },
    averagePreMeal   : { id: 'patientResults.kpi.averagePreMeal' },
    averagePostMeal  : { id: 'patientResults.kpi.averagePostMeal' },
    CV               : { id: 'patientResults.kpi.CV' },
  },

  glucoseConcentrationLevels: {
    header       : { id: 'patientResults.glucoseConcentrationLevels.header' },
    veryHigh     : { id: 'patientResults.glucoseConcentrationLevels.veryHigh' },
    high         : { id: 'patientResults.glucoseConcentrationLevels.high' },
    target       : { id: 'patientResults.glucoseConcentrationLevels.target' },
    low          : { id: 'patientResults.glucoseConcentrationLevels.low' },
    veryLow      : { id: 'patientResults.glucoseConcentrationLevels.veryLow' },
    totalReadings: { id: 'patientResults.glucoseConcentrationLevels.totalReadings' },
    noteTitle    : { id: 'patientResults.glucoseConcentrationLevels.noteTitle' },
  },
};
