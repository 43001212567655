import React from 'react';
import Widget from 'components/Widget';
import GlucoseLevelDistributionChart from 'components/Charts/GlucoseLevelDistributionChart';
import * as constants from '../../constants';
import messages from '../../messages';


class GlucoseLevelDistributionWidget extends React.Component {

  render() {
    return (
      <Widget
        widgetId={constants.GLUCOSE_LEVEL_CONTRIBUTION_WIDGET}
        headerMessage={messages.headers.glucoseLevelDistribution}
        headerAdditionalMessage="%(N)"
      >
        <GlucoseLevelDistributionChart
          {...this.props}
          valueKey="value"
        />
      </Widget>
    );
  }

}


export default GlucoseLevelDistributionWidget;
