import React, { FC, memo, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import Close from 'svg/x.svg';
import { StackDataType } from '../types';
import { getLabel, getTooltipComponent } from './utils';
import Nav from './Nav';
import Dots from './Dots';
import styles from './StackTooltip.pcss';


interface TooltipProps {
  onTooltipClose: () => void;
  data: StackDataType;
  tooltipType: 'measurement' | 'related';
}


const StackTooltip: FC<TooltipProps> = ({ onTooltipClose, data, tooltipType }) => {
  useStyles(styles);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const TooltipComponent = getTooltipComponent(tooltipType);
  const navLabel = getLabel(data[currentIndex], tooltipType);

  const onCloseHandler = (e: MouseEvent) => {
    e.stopPropagation();
    onTooltipClose();
  };

  const onChangeIndexHandler = (newIndex: number) => {
    setCurrentIndex(newIndex);
  };

  return (
    <div className={styles.tooltip}>
      <Close onClick={onCloseHandler} className={styles.tooltip__close} />
      <TooltipComponent element={data[currentIndex]} />
      <Dots currentIndex={currentIndex} indexes={Array.from(data.keys())} />
      <Nav
        onIndexChange={onChangeIndexHandler}
        measurmentsCount={data.length}
        currentIndex={currentIndex}
        label={navLabel}
      />
    </div>
  );
};

export default memo(StackTooltip);
