import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import last from 'lodash/last';
import get from 'lodash/get';


export const getStackedRelatedData = (relatedData: Record<string, number | string>[], width: number) => {
  const readingStackBaseWidth = 20;

  const sortedRelatedData = sortBy(relatedData, 'timestamp');
  const processedRelatedData = [];
  const stackedRelatedData = [];
  const separateRelatedData = [];

  sortedRelatedData.forEach((reading, index) => {
    if (includes(processedRelatedData, reading)) {
      return;
    }

    const { timestamp } = reading;
    const nextReading = sortedRelatedData[index + 1];

    if (!nextReading) {
      separateRelatedData.push(reading);
      return;
    }

    const lastStack = last(stackedRelatedData);
    const lastStackedReading = last(lastStack);

    const lastStackedReadingTimestamp = get(lastStackedReading, 'timestamp');
    if (lastStackedReadingTimestamp
        // eslint-disable-next-line max-len
        && ((timestamp as number - lastStackedReadingTimestamp) / 86400 < ((readingStackBaseWidth + (lastStack.length - 1) * 5)) / width)
    ) {
      lastStack.push(reading);
      processedRelatedData.push(reading);
      return;
    }

    const { timestamp: nextTimestamp } = nextReading;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const timeDiff = nextTimestamp - timestamp;
    if (timeDiff / 86400 > readingStackBaseWidth / width) {
      separateRelatedData.push(reading);
      processedRelatedData.push(reading);
      return;
    }

    if (!lastStack) {
      stackedRelatedData.push([reading, nextReading]);
      processedRelatedData.push(reading, nextReading);
    }

  });

  return {
    stackedRelatedData,
    separateRelatedData,
  };
};
