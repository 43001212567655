import React, { FC, memo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import messages from '../../../messages';
import StackDataList from '../StackDataList';
import styles from './RelatedData.pcss';


interface Props {
  element: Record<string, string | number>;
}


const ActivityTooltip: FC<Props> = ({ element }) => {
  useStyles(styles);

  const data = [
    {
      label: <FormattedMessage {...messages.activity.labels.type} />,
      value: element.name,
    },
    {
      label: <FormattedMessage {...messages.activity.labels.time} />,
      value: moment.unix(element.timestamp as number).utc().format('LT'),
    },
    {
      label: <FormattedMessage {...messages.activity.labels.duration} />,
      value: element.duration,
    },
  ];


  return (
    <>
      <div className={styles.tooltipTitle}>
        <div className={styles.tooltipTitle__titleText}>
          { element.name }
        </div>
      </div>
      <StackDataList data={data} />
    </>
  );
};

export default memo(ActivityTooltip);
