import React, { FC, memo, ReactElement } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { RelatedDataType } from '../../types';
import MealTooltip from './MealTooltip';
import ActivityTooltip from './ActivityTooltip';
import PillTooltip from './PillTooltip';
import InjectionTooltip from './InjectionTooltip';
import styles from './RelatedData.pcss';


interface RelatedDataProps {
  element: RelatedDataType;
}

const RelatedData: FC<RelatedDataProps> = ({ element }) => {
  useStyles(styles);

  let relatedDataTooltip: ReactElement;
  switch (element.type) {
    case 'food':
      relatedDataTooltip = <MealTooltip element={element} />;
      break;
    case 'activity':
      relatedDataTooltip = <ActivityTooltip element={element} />;
      break;
    case 'pill':
      relatedDataTooltip = <PillTooltip element={element} />;
      break;
    case 'injection':
      relatedDataTooltip = <InjectionTooltip element={element} />;
      break;
    default:
      relatedDataTooltip = null;
  }

  return <div>{ relatedDataTooltip }</div>;
};

export default memo(RelatedData);
