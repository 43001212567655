import React, { FC, memo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import messages from '../../../messages';
import StackDataList from '../StackDataList';
import styles from './RelatedData.pcss';


interface Props {
  element: Record<string, string | number>;
}


const InjectionTooltip: FC<Props> = ({ element }) => {
  useStyles(styles);

  const type = element.name === 'Long-acting Insulin'
    ? <FormattedMessage {...messages.injection.labels.longActing} />
    : <FormattedMessage {...messages.injection.labels.factActing} />;

  const data = [
    {
      label: type,
      value: element.value,
    },
    {
      label: <FormattedMessage {...messages.activity.labels.time} />,
      value: moment.unix(element.timestamp as number).utc().format('LT'),
    },
  ];


  return (
    <>
      <div className={styles.tooltipTitle}>
        <div className={styles.tooltipTitle__titleText}>
          { /* eslint-disable-next-line max-len */ }
          <FormattedMessage {...messages.injection.label} /> (<FormattedMessage {...messages.injection.labels.injectionUnit} />)
        </div>
      </div>
      <StackDataList data={data} />
    </>
  );
};

export default memo(InjectionTooltip);
