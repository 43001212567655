import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import isString from 'lodash/isString';


class Widget extends React.PureComponent {

  static propTypes = {
    // Explicit props
    widgetId     : PropTypes.string.isRequired,
    children     : PropTypes.node,
    style        : PropTypes.object,
    headerMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    headerAdditionalMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    isNoHeader                      : PropTypes.bool,
    isInProgress                    : PropTypes.bool,
    className                       : PropTypes.string,
    headerAdditionalMessageClassName: PropTypes.string,
  };

  static defaultProps = {
    isNoHeader: false,
    style     : {},
  };


  renderMessage(message) {
    if (!message) {
      return null;
    }
    if (isString(message)) {
      return message;
    }
    return <FormattedMessage {...message} />;
  }


  renderHeader() {
    const { isNoHeader, headerMessage, headerAdditionalMessage } = this.props;
    if (isNoHeader) {
      return null;
    }
    return (
      <h2 className="widget__header">
        { this.renderMessage(headerMessage)}
        {
          headerAdditionalMessage
            ? (
              <span className={cn('widget__header__additional', this.props.headerAdditionalMessageClassName)}>
                { this.renderMessage(headerAdditionalMessage) }
              </span>
            )
            : null
        }
        <hr />
      </h2>
    );
  }


  render() {
    const { isNoHeader, isInProgress, className, style, widgetId } = this.props;
    return (
      <div
        className={cn('widget', {
          'widget--noHeader'   : isNoHeader,
          'widget--in-progress': isInProgress,
        }, className)}
        data-testid={widgetId}
      >
        <div className="widget__content" style={style}>
          { this.renderHeader() }
          { this.props.children }
        </div>
      </div>
    );
  }

}


export default Widget;
