import React, { FC, memo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import ReadingFlagIcon from 'components/ReadingFlagIcon';
import Account from 'modules/Account';
import MetricConversions from 'libs/MetricConversions';
import Droplet from 'svg/average-blood.svg';
import styles from './Measurement.pcss';


interface MeasurementProps {
  element: MeasurementType;
}

const Measurement: FC<MeasurementProps> = ({ element }) => {
  useStyles(styles);
  const metricsUnits = useSelector(Account.selectors.metricsUnits);
  const metricConversions = new MetricConversions(metricsUnits);
  const conversion = metricConversions.bloodGlucoseConcentration;

  const { displayValue, timestamp, flag, type } = element;
  const measurementTypeClass = styles[`measurement__resultIcon--${type}`];
  const formattedDate = moment.unix(timestamp).utc().format('L');
  const formattedTime = moment.unix(timestamp).utc().format('LT');

  return (
    <div
      className={styles.measurement__result}
      key={`${timestamp}-${flag}-${type}-${displayValue}`}
    >
      <Droplet className={styles.measurement__droplet} />
      <div className={styles.measurement__datetime}>
        <span>{ formattedDate }</span>
        <span>{ formattedTime }</span>
      </div>
      <div className={styles.measurement__value}>
        <ReadingFlagIcon
          className={`${styles.measurement__resultIcon} ${measurementTypeClass}`}
          flag={flag}
        />
        <div className={styles.measurement__resultText}>
          { `${displayValue} ${conversion.unitSymbol}` }
        </div>
      </div>

    </div>
  );
};

export default memo(Measurement);
